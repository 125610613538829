import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../components/Tables";
import { Button } from "../../../components/Forms";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import { PlusIcon } from "@heroicons/react/solid";
import ProspectsBreadcrumbs from "../components/ProspectsBreadcrumbs";

export default function List() {
  const params = useParams();
  const history = useHistory();

  const [activityTasks, setActivityTasks] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);
  const [contact, setContact] = useState({});

  useEffect(() => {

    API.prospective_contact.get(params.contact_id).then((res) => {
      setContact(res);
    })

    API.prospective_contact.activities
      .list(params.contact_id)
      .then((res) => {
        setActivityTasks(res);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setMountLoading(false);
      });
  }, [params.contact_id]);

  return (
    <>
      <div>
      <ProspectsBreadcrumbs panes={[
        { name: `Prospect - #${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/prospective-contacts/${contact._id}`, current: false },
        { name: `Activities`, current: true }
      ]} />
        <Segment className="mt-4">
          <div>
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">Activities</span>
              <Button
                colour="positive"
                label="Create"
                onClick={() =>
                  history.push(
                    `/prospective-contacts/${params.contact_id}/activities/add`
                  )
                }
                className="ml-auto"
                icon={PlusIcon}
              />
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <div className="md:grid md:grid-cols-3 md:gap-34 pt-3 pb-4">
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">{activityTasks.length}</div>
                  <div className="text-xl text-primary">Activities</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {activityTasks.length > 0
                      ? activityTasks
                          .map((item) => item.time)
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {activityTasks.length > 0
                      ? activityTasks
                          .map((item) =>
                            item.isBillable === true ? item.time : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes Billable</div>
                </div>
              </div>
              <ThemeTable
                header={[
                  { text: "Activity", value: "description" },
                  { text: "Fee Earner", value: "assigned" },
                  { text: "Due Date", value: "due_date" },
                  { text: "Time", value: "time" },
                  { text: "Cost", value: "cost" },
                  { text: "Billable", value: "isBillable" },
                  { text: "Status", value: "status" },
                ]}
                body={activityTasks
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((item) => ({
                  ...item,
                  isBillable: item.isBillable === true ? "Yes" : "No",
                  matter_display_number:
                    item.matter && item.matter !== null
                      ? item.matter.display_number
                      : "--",
                  assignee_name: item.assignee ? item.assignee.name : "--",
                  due_date: item.due_date
                    ? dayjs(item.due_date).format("DD/MM/YYYY HH:mm:ss")
                    : "",
                  status: item.complete ? "Complete" : "In Progress",
                  extra_row_css: item.complete ? "text-gray-400" : "text-gray-900",
                  cost: `
                  £${(
                    (item.time ? item.time : 0) *
                    (item.currentRate ? item.currentRate / 60 : 0)
                  ).toFixed(2)}`,
                }))}
                action={(item) =>
                  history.push(
                    "/prospective-contacts/" +
                      params.contact_id +
                      "/activities/" +
                      item._id
                  )
                }
                noDataMessage="No Activities..."
              />
            </>
          )}
        </Segment>
      </div>
    </>
  );
}
