import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  DateSelect,
  Input,
  SelectTwo,
} from "../../../components/Forms";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";

export default function Add_Payment_Form({ data, debts = [], payments = [], fees, matter_display_number, invoiceType}) {
  // const [debts, setDebts] = useState({});
  const [payment, setPayment] = useState({
    reference: "",
    paymentDate: "",
    paymentDue: "",
    paymentType: "",
    amount: 0,
    VAT: 0.2,
    TaxRate: "",
  });
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [mountLoading, setMountLoading] = useState(true);

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];



  useEffect(() => {

    if(mountLoading){

      let updatePayment = payment;
      updatePayment.reference = `${matter_display_number}.${invoiceType}${payments.length + 1}`;

      setPayment(updatePayment);

      API.organisations.getMyOrg().then(r => {

        let vat = 0;
        let tax = "";

        switch(r.xeroTaxRate){
            case "20percent":
                vat = 0.2;
                tax = "20%";
              break;
            case "5percent":
                vat = 0.05;
                tax = "5%";
              break;
            case "exempt":
                vat = 0;
                tax = "Exempt";
              break;
            case "novat":
                vat = 0;
                tax = "No VAT";
              break;
            case "zeroECgoods":
                vat = 0;
                tax = "Zero Rated EC Goods Income";
              break;
            case "zeroECservices":
                vat = 0;
                tax = "Zero Rated EC Services";
              break;
            case "zerorated":
                vat = 0;
                tax = "Zero Rated Income";
              break;
        }

        setPayment({...payment, VAT: vat, TaxRate: tax});

        
      });

      setMountLoading(false);

    }


    validate();
  }, [payment]);

  const onChange = (e) => {

    if(e.target.name === "amount"){
      if(e.target.value < 0){
        return false;
      }

      let total_balance = 0;
      let theDebt = debts.findIndex(e => e._id === payment._fee_id);

      if(theDebt !== -1){
        let d = debts[theDebt];
        total_balance = (parseFloat(d.amount)/* + parseFloat(d.amount * 0.2)*/) - parseFloat(d.amountPaid);
      }
      
      /*

      let theDebts = debts;

      theDebts.forEach(debt => {

        let amount = debt.amount ? parseFloat(debt.amount) +  parseFloat(debt.amount * 0.2) : 0;
        let amountPaid = debt.amount ? parseFloat(debt.amountPaid) +  parseFloat(debt.amountPaid * 0.2) : 0;

        let balance = amount - amountPaid;

        total_balance = total_balance + balance;

        let balanceAfter = balance - amount;

        if(balanceAfter < 0){
          debt.paymentAmount = balance;
        }else{
          debt.paymentAmount = amount;
        }

        amount = balanceAfter < 0 ? Math.abs(balanceAfter) : 0;

      });
      */

      if(parseFloat(e.target.value) > parseFloat(total_balance).toFixed(2)){
        toast.error(`£${e.target.value} can't be entered as this is above the total for the Fee, Fee total is £${parseFloat(total_balance) ? parseFloat(total_balance).toFixed(2) : total_balance}`)
        setPayment({ ...payment, [e.target.name]: "" });
        return false;
      }

    }

      setPayment({ ...payment, [e.target.name]: e.target.value });
  };
  const submitData = () => {

    if(Object.keys(warnings).length >= 1 || Object.keys(errors).length >= 1){
      toast.error("There are validation issues that must be resolved before continuing.");
      return;
    }
    data(payment);
  };
  const validate = () => {
    let warnings = {};
    setWarnings(warnings);

    const errors = {};
    if (!payment.amount || !payment.amount > 0) errors.amount = true;
    if (!payment.reference) errors.reference = true;
    if (!payment._fee_id || payment._fee_id === "") errors._fee_id = true;

    if(Object.keys(errors).length > 0) {
      errors.notcomplete = true;
    }

    setErrors(errors);
    return errors;
  };

  let total_paid = 0;
  let total_balance = 0;
  let total_after = 0;
  let total_payment = 0;

  let debts_body = debts.map((debt, index) => {
    // let outstanding = debt.amount ? parseFloat(debt.amount) + parseFloat(debt.amount * 0.2) : 0;
    let outstanding = debt.amount ? parseFloat(debt.amount) + parseFloat(debt.amount * payment.VAT) : 0; // use settings

    let balance = outstanding;

    total_paid = total_paid + (parseFloat(debt.amountPaid) ? parseFloat(debt.amountPaid) : 0);
    total_balance = total_balance + balance;
    total_after = total_after + (balance - (parseFloat(debt.amountPaid) ? parseFloat(debt.amountPaid) : 0));
    // total_payment = total_payment + (parseFloat(debt.paymentAmount) ? parseFloat(debt.paymentAmount) : 0);

    return (
    <tr key={index} className="p-2 divide-x divide-gray-300 hover:bg-gray-50">
      <td className="p2">
        {dayjs(debt.invoiceDate) ? dayjs(debt.invoiceDate).format("DD MMMM YYYY") : "N/A"}
      </td>
      <td className="p2">
        #{debt.invoice_num}
      </td>
      <td className="p2">
        {debt.reference}
      </td>
      <td className="p2">
        £{parseFloat(debt.amountPaid) ? CommonFunctions.toFormattedFixed(parseFloat(debt.amountPaid)) : "0.00"}
      </td>
      <td className="p2">
        £{parseFloat(debt.amount) ? CommonFunctions.toFormattedFixed(parseFloat(debt.amount)) : "0.00"}
      </td>
      <td className="p2">
        £{debt.amount && debt.amountPaid ? CommonFunctions.toFormattedFixed(parseFloat(debt.amount) - parseFloat(debt.amountPaid)) : "0.00"}
      </td>
    </tr>
  )});

  return (
    <div className="w-full p-2 ">
      <div className="bg-red-500">
        {warnings && warnings.warn &&
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8 mb-5">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg">
                <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
                <span className="hidden md:inline"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
              </p>
            </div>
          </div>
        </div> }
    </div>
      <div className="w-full gap-2">
        <div className="text-center pb-4 font-medium text-lg  border-b border-gray-300 mx-10 mb-2">
          Fee Payment
        </div>
        <div className="grid grid-cols-2 gap-4 ">
          <div className="grid grid-cols-5 col-span-2 gap-4 border-r border-gray-100 p-2">

            <div className="col-span-6 sm:col-span-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Fee
              </label>

              <SelectTwo
                placeholder="Select Fee"
                field="_fee_id"
                options={debts.filter(d => d.xeroInvoiceID ? d.xeroStatus == "AUTHORISED" : true).map((d) => {
                  return {
                    text: d.reference,
                    value: d._id
                  }
                })}
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={payment._fee_id}
                onChange={onChange}
                error={errors._fee_id}
              />
            </div>

            <div className="col-span-6 sm:col-span-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Payment Type
              </label>

              <SelectTwo
                placeholder="Select Method"
                field="paymentType"
                options={[
                  {text: "Invoice", value: "invoice"},
                  {text: "Sales Credit", value: "sales_credit"},
                ]}
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={payment.paymentType}
                onChange={onChange}
              />
            </div>


            <Input 
              label="Number"
              name="number"
              onChange={onChange}
              value={payment.number}
              error={errors.number}
            />

            <Input 
              label="Reference"
              name="reference"
              onChange={onChange}
              value={payment.reference}
              error={errors.reference}
            />

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Issue Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setPayment({
                    ...payment,
                    issueDate: date,
                  })
                }
                value={payment.issueDate ? new Date(payment.issueDate) : ""}
                name="issueDate"
                className={`relative w-full p-2 ${
                  errors.issueDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Due Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setPayment({
                    ...payment,
                    dueDate: date,
                  })
                }
                value={payment.dueDate ? new Date(payment.dueDate) : ""}
                name="dueDate"
                className={`relative w-full p-2 ${
                  errors.dueDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>
                
            <Input
              label="Total Amount Paid"
              type={"number"}
              name={"amount"}
              onChange={onChange}
              placeholder="00.00"
              value={payment.amount}
              error={errors.amount}
              disabled={!payment._fee_id || !payment._fee_id === ""}
              center={true}
              minimum={0}
            />
            <span>VAT - {payment.TaxRate} (From Organisation Settings) :
               £{parseFloat(payment.amount) ? CommonFunctions.toFormattedFixed(parseFloat(payment.amount) * payment.VAT) : "0.00"}  </span>

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Paid Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setPayment({
                    ...payment,
                    paidDate: date,
                  })
                }
                value={payment.paidDate ? new Date(payment.paidDate) : ""}
                name="paidDate"
                className={`relative w-full p-2 ${
                  errors.paidDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : "border-gray-300"
                } `}
              />
            </div>

          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4 p-2">
          <div className="flex justify-between border border-gray-100 p-2">
            Total Paid:
            <span>
              £{parseFloat(payment.amount) ? CommonFunctions.toFormattedFixed(parseFloat(payment.amount)) : "0.00"}
            </span>
          </div>
          {/*  
          <div className="flex justify-between border border-gray-100 p-2">
            Fee Total:
            <span>
              £{parseFloat(total_balance) ? CommonFunctions.toFormattedFixed(parseFloat(total_balance)) : "0.00"}
            </span>
          </div>*/}
          <div className="flex justify-between border border-gray-100 p-2">
            Balance of Fees:
            <span>
              £{parseFloat(total_after) ? CommonFunctions.toFormattedFixed(parseFloat(total_after)) : "0.00"}
            </span>
          </div>
        </div>
        <div className="w-full gap-4 mt-4 p-2">
        <table className="w-full border border-gray-400">
          <thead className="bg-gray-50">
            <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
              <th scope="col" className="font-medium">
                Date
              </th>
              <th scope="col" className="font-medium">
                Invoice Number
              </th>
              <th scope="col" className="font-medium">
                Reference
              </th>
              <th scope="col" className="font-medium">
                Amount Paid
              </th>
              <th scope="col" className="font-medium">
                Amount Due
              </th>
              {/*  
              <th scope="col" className="font-medium">
                Payment Amount
              </th>*/}
              <th scope="col" className="font-medium">
                Balance After Payment
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {debts_body}

            <tr className="p-2 divide-x divide-gray-300 bg-gray-200">
              <td></td>
              <td></td>
              <td className="text-right p-2 border-r border-gray-400">Totals:</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_paid) ? CommonFunctions.toFormattedFixed(parseFloat(total_paid)) : "0.00"}</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_balance) ? CommonFunctions.toFormattedFixed(parseFloat(total_balance)) : "0.00"}</td>
              {/* <td className="p-2 border-r border-gray-400">£{parseFloat(total_payment) ? CommonFunctions.toFormattedFixed(parseFloat(total_payment)) : "0.00"}</td> */}
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_after) ? CommonFunctions.toFormattedFixed(parseFloat(total_after)) : "0.00"}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className="flex justify-between mt-3 border-t border-gray-100 py-4">
          {/*  
          <Button
            colour="red"
            onClick={() => {
              history.push(`/matters/${matter._id}/debtors`)
            }}
            label="Cancel"
          />*/}
          <Button 
            colour="positive" 
            onClick={submitData} 
            // disabled={errors.notcomplete} 
            label={"Add"}
           />
        </div>
      </div>
    </div>
  );
}
