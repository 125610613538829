import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import API from "../../api";
import Segment from "../../components/Segment";
import CommonFunctions from "../../CommonFunctions";
import dayjs from "dayjs";
import MatterReceiver from "../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import Checklist from "../../components/_checklists";
import MatterBar from "./_components/MatterBar";
import MatterBreadcrumbs from "./_components/MatterBreadcrumbs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, DateSelect, Input, SelectTwo } from "../../components/Forms";
import toast from "react-hot-toast";
import { Authentication } from "../../plugins/clio_auth";
import { ThemeTable } from "../../components/Tables";
import Select2 from "../../components/Forms/Select2";
import { GiMagnifyingGlass, GiPencil } from "react-icons/gi";

function ShowMatter({ history, matter }) {
  const [matterDebtors, setMatterDebtors] = useState({});
  const [matterFees, setMatterFees] = useState([]);
  const [courts, setCourts] = useState([]);
  const [clioUsers, setClioUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMilestones, setLoadingMilestones] = useState(false);
  const [applicableFee, setApplicableFee] = useState({});
  const [correspondentData, setCorrespondentData] = useState({});
  useEffect(() => {
    matter._refresh();
  }, []);

  useEffect(() => {
    if (matter._id) {
      setLoading(true);

      Promise.all([
        API.matters.debtors.getAll(matter._id),
        API.matters.fees.getAll(matter._id),
        API.users.getAll(),
        API.settings.courts.get()
        // API.settings.correspondents().list(matter.organisation ? matter.organisation._id : null)
      ]
      ).then((res) => {
        setMatterDebtors(res[0]);
        setMatterFees(res[1]);
        setClioUsers(
          res[2].users.map((a, index) => ({
            key: index,
            text: a.name,
            value: a._clioId,
          }))
        );
        /*
        setCorrespondentList(res[3].map(c => {
          return {value: c._id, text: c.type}
        }))*/
        setCourts(res[3].map(c => {
          return {value: c._id, text: c.name}
        }))
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setLoading(false);
      });

    }

    const feeThresholds = matter.fee_thresholds ? matter.fee_thresholds.map(fee => ({
      ...fee,
      threshold: parseFloat(fee.threshold)
    })) : [];

    const fee = feeThresholds.find((fee, index) => {
      const nextFee = feeThresholds[index + 1];
      return (
        (nextFee 
          ? fee.threshold > matterDebtors.totalOwed && matterDebtors.totalOwed <= nextFee.threshold
          : matterDebtors.totalOwed > fee.threshold)
      );
    });

    if (fee) {
      setApplicableFee(fee);
    }

  }, [matter]);

  /*
  useEffect(() => {
    let d = correspondentList.find(c => c.value === correspondentData.correspondentType);

    if(d){
      setCorrespondentTypeText(d.text);
    }

  }, [correspondentData]); */

  const milestoneDate = (title, name) => {
    return (
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          {title}

          {Authentication.can("matter.milestones.update") && name !== "open_date" &&
          <Button
              style={{display: "inline-block", marginLeft: "3px"}}
              colour="alert"
              label="X"
              onClick={() => {

                if(!matter.practice_area){
                  toast.error("Matter Requires Practice Area!")
                }else{
                  setLoadingMilestones(true);
                  API.matters.update(matter._id, {[name]: null}).then((res) => {
                    if(res.success){
                      toast.success("Milestone '"+title+"' date removed!")
                      matter._refresh();
                    }
                    setLoadingMilestones(false);
                  });
                }

              }}
            />}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
            
            <DateSelect
              name={name}
              value={matter[name]}
              disabled={matter.status === "closed"}
              onChange={(date) => {
                if(!matter.practice_area){
                  toast.error("Matter Requires Practice Area!");
                }else{
                  setLoadingMilestones(true);
                  API.matters.update(matter._id, {[name]: date}).then((res) => {
                    if(res.success){
                      toast.success("Milestone '"+title+"' date updated!")
                      matter._refresh();
                    }
                    setLoadingMilestones(false);

                  });
                }
              }}
            />
            
        </dd>
      </div>

    );
  }  

  const feeThresholds = matter.fee_thresholds ? matter.fee_thresholds.map(fee => ({
    ...fee,
    threshold: parseFloat(fee.threshold)
  })) : [];

  return (
    <>
    <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: '#', current: true },
      ]} />
    
    <Segment>
      <MatterBar matter={matter} history={history} showEdit />

      <div className="md:grid md:grid-cols-2">
        <div className="p-4">
          <div className="border border-primary bg-white rounded-lg p-4 h-full">
            {loading ? (
              <Loader />
            ) : (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.contact ? (
                            matter.contact.name + (matter.contact.suffix ? " "+matter.contact.suffix : "") ? (
                              <a
                                className="underline hover:text-blue-500"
                                href={`/contacts/${matter.contact._id}`}
                              >
                                {matter.contact.name}{matter.contact.suffix ? " "+matter.contact.suffix : ""}
                              </a>
                            ) : (
                              <a
                                className="underline hover:text-blue-500"
                                href={`/contacts/${matter.contact._id}`}
                              >
                                {matter.contact.first_name}{" "}
                                {matter.contact.middle_name}{" "}
                                {matter.contact.last_name}
                              </a>
                            )
                          ) : (
                            "--"
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Fee Earner
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.responsible_attorney
                            ? matter.responsible_attorney.name
                            : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.status ? matter.status : "--"}
                        </dd>
                      </div>
                    </dl>
                    <p>&nbsp;</p>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                      {/*  
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Open Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.open_date
                            ? dayjs(matter.open_date).format("DD/MM/YYYY HH:mm")
                            : ""}
                        </dd>
                      </div>*/}
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Client Reference
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.client_reference}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Practice Area
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.practice_area
                            ? matter.practice_area.name
                            : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Billable
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.billable ? "Yes" : "No"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Billing Method
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.billing_method ? matter.billing_method : "--"}
                        </dd>
                      </div>
                      
                      <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                          Description
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.description ? matter.description : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                          Fees
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul>
                            <li className={matter.upfront_fee > 0 ? "text-primary font-bold" : ""}> Upfront Fee (setup / LBA): {" "}
                              {matter.upfront_fee ? `${matter.upfront_fee_type == "fixed" ? "£" : ""}${matter.upfront_fee}${matter.upfront_fee_type == "percent" ? "%" : ""}` : `Not Set!!`} 
                            </li>
                              {matter.scale == "slidingScale" && "Sliding Scale for Success Fee:"}
                              {matter.scale == "stageScale" && "Stage Scale for Success Fees:"}
                              {matter.scale == "hourlyScale" && "Hourly Matter Fees:"}
                              {feeThresholds && feeThresholds.length > 0 ? 
                                feeThresholds.map((fee, index) => {
                                  const isApplicable = (fee.fee == applicableFee.fee && fee.threshold === applicableFee.threshold && fee.over_threshold === applicableFee.over_threshold) ? true : false ;
                                  let user = clioUsers.filter(u => u.value == fee.user);

                                  return (
                                    <li key={index} className={matter.scale !== "slidingScale" ? (fee.stage == matter.stage ? "text-primary font-bold" : "")  : (isApplicable ? "text-primary font-bold" : "")}>
                                      - 
                                      {fee.scale == "hourlyScale" ? <>
                                        {fee.user && user[0] && user[0].text} - Fee: £{fee.fee.toLocaleString()} per/hour
                                      </> 
                                      :matter.scale == "stageScale" ? <>
                                      Stage: {fee.stage} - Fee: £{fee.fee.toLocaleString()}
                                    </> :
                                    <>
                                      {`${fee.over_threshold ? "Over:" : "Up to:"} £${fee.threshold.toLocaleString()}`} - Fee: {fee.type === "fixed" ? "£" : ""}{fee.fee.toLocaleString()}{fee.type === "percent" ? "%" : ""}
                                    </>}
                                    </li>
                                  );
                                }) : 
                                <li>No Threshold Fees Set!!</li>
                              }
                            {/* <li>Up to: £200 - £80 fee</li> */}
                          </ul>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
            )}
          </div>
        </div>
        

        <Segment>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Milestones
                    </dt>
                  </div>
                </dl>
                <p>&nbsp;</p>

                  {loadingMilestones ? <Loader className="text-center" /> : <>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                  {milestoneDate("Case Opened", "open_date")}
                  {milestoneDate("Claim Prepared", "claim_prepared_date")}
                  {milestoneDate("Claim Sent to Court", "claim_sent_date")}
                  {milestoneDate("Date of Issue", "issue_date")}
                  {milestoneDate("Date of Judgment", "judgement_date")}
                  {milestoneDate("Judgment Received On", "judgement_received_date")}
                  {milestoneDate("Case Closed", "close_date")}
                  </dl></> }
                
              </div>
            </div>
        </Segment>

        <div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
            <div className="border border-primary bg-white rounded-lg px-4 pb-2 mt-2">
              <div className="text-center text-xl py-2 text-primary">
                Fees
              </div>
              <div className="flex justify-around mb-4">
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    {applicableFee && 
                      applicableFee.type === "fixed" ? `£${applicableFee.fee ? CommonFunctions.toFormattedFixed(applicableFee.fee) : ""}` 
                      : applicableFee.type === "percent" ? `£${applicableFee.fee ? CommonFunctions.toFormattedFixed(parseFloat(matterDebtors.totalOwed) * parseFloat(applicableFee.fee / 100)) : ""} (${applicableFee.fee ? applicableFee.fee.toLocaleString() : ""}%)` : ""}
                  </div>
                  <div className="text-xl text-primary">Debt Fees</div>
                </div>
                <div className=" p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    £{matterFees && CommonFunctions.toFormattedFixed(matterFees.reduce((acc, fee) => acc + fee.amount, 0))}
                  </div>
                  <div className="text-xl text-primary">Total Fees ({matterFees ? matterFees.length : 0})</div>
                </div>
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                  £{matterFees && CommonFunctions.toFormattedFixed(matterFees.reduce((acc, fee) => acc + ((parseFloat(fee.amount)) - (fee.amountPaid ? parseFloat(fee.amountPaid) : 0)), 0))}
                  </div>
                  <div className="text-xl text-primary">Outstanding Fees</div>
                </div>
                
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
            <div className="border border-primary bg-white rounded-lg px-4 pb-2 mt-2">
              <div className="text-center text-xl py-2 text-primary">
                Debtors
              </div>
              <div className="flex justify-around mb-4">
                <div className=" p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    {matterDebtors.debtors && matterDebtors.debtors.length > 0 ? 
                    matterDebtors.debtors && matterDebtors.debtors.map(debtor => {
                      return debtor.debtor_name
                    }).join(', ')
                      : ""}
                  </div>
                  <div className="text-xl text-primary">Debtors ({matterDebtors.debtors ? matterDebtors.debtors.length : 0})</div>
                </div>
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    £{matterDebtors.totalOwed
                      // ? matterDebtors.totalOwed.toLocaleString("en-GB")
                      ? CommonFunctions.toFormattedFixed(matterDebtors.totalOwed)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Amount Owed</div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
              <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                <div className="text-center text-xl py-2 text-primary">
                  Clio Contacts - Custom Fields (defendant1, claiment1 & courtname1) <br/> *Old Fields pulled from Clio, if no correspondents on Matter they will be generated and shown in "Correspondents" section below this!
                </div>
                <div className="flex justify-around mb-4">
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                        {matter.defendant1 ? <Link to={"/contacts/"+matter.defendant1._id}>
                          {matter.defendant1.name} {matter.defendant1.suffix ? matter.defendant1.suffix : ""}
                        </Link> : matter._defendant1}
                    </div>
                    <div className="text-xl text-primary">Defendant1</div>
                  </div>
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                      {matter.claiment1 ? <Link to={"/contacts/"+matter.claiment1._id}>
                        {matter.claiment1.name} {matter.claiment1.suffix ? matter.claiment1.suffix : ""}
                      </Link> : matter._claiment1}
                    </div>
                    <div className="text-xl text-primary">Claiment1</div>
                  </div>
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                      {matter.court1 ? <Link to={"/contacts/"+matter.court1._id}>
                        {matter.court1.name} {matter.court1.suffix ? matter.court1.suffix : ""}
                      </Link> : matter._courtname1}
                    </div>
                    <div className="text-xl text-primary">Court1</div>
                  </div>
                  
                </div>
              </div>
            </div>

          <div className="py-2 px-4">
              <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                <div className="text-center text-xl py-2 text-primary">
                  Correspondents - Custom Fields (defendant1 etc.)
                </div>
                <div className="justify-around mb-4">

                  <Input
                    name={"name"}
                    label={"Title"}
                    value={correspondentData.name}
                    onChange={(e) => setCorrespondentData({...correspondentData, [e.target.name]: e.target.value})}
                  />
                  <SelectTwo
                    label="Correspondent"
                    field="correspondentType"
                    onChange={(e) => setCorrespondentData({...correspondentData, correspondentContact: null, correspondentDebtor: null, [e.target.name]: e.target.value})}
                    value={correspondentData.correspondentType}
                    options={[
                      {value: "contact", text: "Client (3rd Party)"},
                      {value: "debtor", text: "Debtor"},
                      {value: "court", text: "Court"},
                      {value: "agent", text: "Agent"},
                      // {value: "user", text: "user"},
                      // {value: "matter", text: "matter"},
                    ]}
                  />
                  <p>Select type before able to Select the relevant client </p>
                  {correspondentData.correspondentType === "contact" &&
                    <SelectTwo
                      label="Client (3rd Party)"
                      field="correspondentContact"
                      onChange={(e) => setCorrespondentData({...correspondentData, [e.target.name]: e.target.value})}
                      value={correspondentData.correspondentContact}
                      options={matter.orgContacts}
                    /> }
                    {/* {console.log("debtors", matterDebtors)} */}
                  {correspondentData.correspondentType === "debtor" &&
                    <SelectTwo
                      label="Debtor"
                      field="correspondentDebtor"
                      onChange={(e) => setCorrespondentData({...correspondentData, [e.target.name]: e.target.value})}
                      value={correspondentData.correspondentDebtor}
                      options={matterDebtors.debtors.map(d => {return {value: d._id, text: d.debtor_name}})}
                    /> }
                  {correspondentData.correspondentType === "court" &&
                    <SelectTwo
                      label="Court"
                      field="correspondentCourt"
                      onChange={(e) => setCorrespondentData({...correspondentData, [e.target.name]: e.target.value})}
                      value={correspondentData.correspondentCourt}
                      options={courts}
                    /> }
                    
                  {!correspondentData.edit ?
                  <Button 
                    className={"mt-2"}
                    label="Add"
                    color="positive"
                    disabled={!correspondentData.name || !correspondentData.correspondentType 
                      // ||(!correspondentData.correspondentContact && !correspondentData.correspondentDebtor || correspondentData.correspondentCourt)
                    }
                    onClick={() => API.matters.addCorrespondent(matter._id, correspondentData).then(r => {
                      if(r.success){
                        toast.success(r.message);
                        matter._refresh();
                        setCorrespondentData({});
                      }else{
                        toast.error(r.message);
                      }
                    })}
                  /> :
                  <div className={"flex space-x-2 mt-2"}>
                    <Button 
                      label="Cancel"
                      color="alert"
                      onClick={() => setCorrespondentData({})}
                    />
                    <Button 
                      label="Edit"
                      color="warning"
                      onClick={() => API.matters.updateCorrespondent(matter._id, correspondentData._id, correspondentData).then(r => {
                        if(r.success){
                          toast.success(r.message);
                          matter._refresh();
                          setCorrespondentData({});
                        }else{
                          toast.error(r.message);
                        }
                      })}
                    />
                  </div>}

                  <ThemeTable
                  selectable={false}
                  viewButon={false}
                    header={[
                      { text: "Correspondent", value: "name" },
                      { text: "Type", value: "type" },
                      { text: "Name", value: "correspondent_name" },
                      { text: "Actions", value: "actions" },
                    ]}
                    body={matter.correspondents && matter.correspondents.map(c => {

                        let cContact = c.correspondentType == "contact" ? c.correspondentContact : null;
                        let cMatter = c.correspondentType == "matter" ? c.correspondentMatter: null;
                        let cDebtor = c.correspondentType == "debtor" ? c.correspondentDebtor : null;
                        let cCourt = c.correspondentType == "court" ? c.correspondentCourt : null;

                        let correspondent_name = ".";

                          switch(c.correspondentType){
                            case "contact":
                              correspondent_name = c.correspondentContact ? c.correspondentContact.name : "Unknown Contact!";
                            break;
                            case "matter":
                              correspondent_name = c.correspondentMatter ? c.correspondentMatter.display_number : "Unknown Matter!";
                            break;
                            case "debtor":
                              correspondent_name = c.correspondentDebtor ? c.correspondentDebtor.debtor_name : "Unknown Debtor!";
                            break;
                            case "court":
                              correspondent_name = c.correspondentCourt ? c.correspondentCourt.name : "Unknown Court!";
                            break;
                          }

                          const capitalizeFirstLetter = (string) => {
                            return string.charAt(0).toUpperCase() + string.slice(1);
                          };
                        
                        return {
                          ...c,
                          contact: cContact ? true : false,
                          matter: cMatter ? true : false,
                          debtor: cDebtor ? true : false,
                          court: cCourt ? true : false,
                          type: c.correspondentType === "contact" ? "Client (3rd Party)" : capitalizeFirstLetter(c.correspondentType),
                          correspondent_name, 
                          actions: <div className={"flex space-x-2"}>
                            <Button 
                              icon={GiMagnifyingGlass}
                              className={" px-2 py-2"}
                              color="action"
                              onClick={() => {
                                if(cContact){
                                  history.push("/contacts/" + cContact._id)
                                }else if(cMatter){
                                  history.push("/matters/" + cMatter._id)
                                }else if(cDebtor){
                                  history.push("/matters/" + matter._id + "/debtors/" + cDebtor._id)
                                }else if(cCourt){
                                  history.push("/settings/courts")
                                }else{
                                  toast.error("Issue with Correspondent to View!")
                                }
                              }}
                            />
                            <Button 
                              className={"px-2 py-2"}
                              icon={GiPencil}
                              color="warning"
                              onClick={() => setCorrespondentData({
                                ...c,
                                edit: true,
                                correspondentContact: c.correspondentContact ? c.correspondentContact._id : null,
                                correspondentDebtor: c.correspondentDebtor ? c.correspondentDebtor._id : null,
                              })}
                            />
                          </div>
                        }
                      }
                    )}
                    noDataMessage="No Correspondents..."
                  />                  
                </div>
              </div>
            </div>

        </div>

        <Segment>
          <Checklist
              type="matter"
              parentId={matter._id}
              createTask={async (checklist_id) =>
                await Promise.all([
                  API.settings
                    .checklist("matter")
                    .createChecklistTask(matter._id, checklist_id),
                ])
              }
            />
        </Segment>

      </div>
    </Segment>
    </>
    
  );
}
export default ChainedContext(ShowMatter, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
