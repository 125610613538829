import React, { useState, useEffect } from "react";
import Segment from "../../../components/Segment";
import Add_Debt_Form from "../debtors/debt/_forms/Add_Debt_Form";

import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../api";
import MatterBar from "../_components/MatterBar";
import toast from "react-hot-toast";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import CommonFunctions from "../../../CommonFunctions";
function AddDebt({ matter, debtor, history }) {

  const [loading, setLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});
  const [debts, setDebts] = useState([]);

  const [matterDebtors, setMatterDebtors] = useState({});

  useEffect(() => {
    API.matters.debtors
      .getAll(matter._id)
      .then((res) => {
        setMatterDebtors(res);        
      })
        
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  useEffect(() => {
    if(loading)
      Promise.all([
        API.settings.base_rates.normal.get(),
        API.matters.debtors.getAll(matter._id)
      ])
      .then((data) => {
        if(data[0][0]){
          setBaseRate(data[0]);
        }

        API.matters.debtors.debt.getAll(matter._id, 0, data[1].debtors ? data[1].debtors.map(d => d._id) : []).then((r) => {
          setDebts(r);
          setLoading(false);
        });
      });
  }, [baseRate]);
  const handleData = (data) => {
    let formData = data;
    formData.matter = matter._id;
    formData.debtor = data.debtor;
    API.matters.debtors.debt
      .create(matter._id, data.debtor, formData)
      .then((res) => {
        toast.success("Debt added");
        history.push(`/matters/${matter._id}/debts`);
      });
  };
  return (
    <div className="w-full  ">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Debts", href: `/matters/${matter._id}/debts`,},
        { name: "Add", href: `#`, current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        <Add_Debt_Form
          validateSubmit={(data) => {}}
          selectDebtor={true}
          data={handleData}
          contact_settings={matter.contact.settings}
          matter_settings={{paymentTerms: matter.paymentTerms}}
          interest_rate={matter.interest_rate ? matter.interest_rate : (matter.contact.interest_rate ? matter.contact.interest_rate : (baseRate ? baseRate.rate : 0)) }
          matter_display_number={matter.display_number}
          debts={debts}
        />
      </Segment>
    </div>
  );
}
export default ChainedContext(AddDebt, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
