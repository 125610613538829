import React from "react";
import dayjs from "dayjs";
import { DataGridPro, GridRowsProp, GridColDef } from "@mui/x-data-grid-pro";
import CommonFunctions from "../../../../CommonFunctions";
import { Button } from "../../../../components/Forms";
import { GiMagnifyingGlass } from "react-icons/gi";
import { TrashIcon } from "@heroicons/react/solid";
import API from "../../../../api";
import toast from "react-hot-toast";
import { ConfirmModal } from "../../../../components/Modals";

export default function DebtSchedule({ debts, history }) {
  let credit = 0;
  let amount = 0;
  let due_amount = 0;
  let total_amount_payments = 0;
  let total_amount = 0;
  let total_paid = 0;
  let interest = 0;
  let compensation = 0;
  let recoverable_legal_costs = 0;
  function percentage(num, per) {
    return (num / 100) * per;
  }
  const getInterest = (value, interest) => {
    let fig1 = value / 100;
    let fig2 = interest / 365;
    return fig1 * fig2;
  };
  for (let index = 0; index < debts.length; index++) {
    const debt = debts[index];
    if (debt.amount) {
      total_amount = total_amount + debt.amount;
      total_paid = total_paid + debt.AmountPaid;
      amount = amount + (debt.amount - (debt.AmountPaid ? debt.AmountPaid : 0));
      due_amount = due_amount + debt.amount;
      compensation = compensation + debt.compensation;
      recoverable_legal_costs = recoverable_legal_costs + (debt.recoverable_legal_costs ? debt.recoverable_legal_costs : 0);
      let days = dayjs().diff(dayjs(debt.interestDate), "day");
      interest = interest + getInterest(debt.amount, debt.interest) * days;
    }
    if (debt.credit) {
      credit = credit + debt.credit;
    }
  }

  let total_due = due_amount + (interest ? interest : 0) + (recoverable_legal_costs ? recoverable_legal_costs : 0) + (compensation ? compensation : 0) - (credit ? credit : 0);

  let total = amount + (interest ? interest : 0) + (recoverable_legal_costs ? recoverable_legal_costs : 0) + (compensation ? compensation : 0) - (credit ? credit : 0);

  let arr = [];
  let arr_temp = [];

  debts.map((d, i) => {
    d.transactions.map((t,ind) => {
      total_amount_payments = total_amount_payments + (parseFloat(t.credit) ? parseFloat(t.credit) : 0);

      arr_temp.push({
        createdAt: t.createdAt,
        id: t._id,
        debtor: d.debtor,
        matter: t.matter,
        debt: t.debt,
        uuid: t.uuid,
        reference: t.reference,
        paymentAmount: `£${parseFloat(t.paymentAmount) ? CommonFunctions.toFormattedFixed(parseFloat(t.paymentAmount)) : 0.00}`,
        invoice_num: d.invoice_num,
        rawDate: t.transactionDate,
        transactionDate: dayjs(t.transactionDate).isValid() ? dayjs(t.transactionDate).format("DD/MM/YYYY") : "-",
        createdAt: dayjs(t.createdAt).isValid() ? dayjs(t.createdAt).format("DD/MM/YYYY") : "-",
        paymentType: t.paymentType ? t.paymentType.title : "-",
        // due: dayjs(t.dateDue).isValid() ? dayjs(t.dateDue).format("DD/MM/YYYY") : "-",
        credit: `£${parseFloat(t.credit) ? CommonFunctions.toFormattedFixed(parseFloat(t.credit)) : 0.00}`,
      });

    });

  });

  for (let a of arr_temp) {
    let findArr = arr.findIndex(b => b.reference === a.reference);
  
    if (findArr === -1) {
      arr.push({
        ...a,
        invoices: [{ invoice_num: a.invoice_num, credit: a.credit }],
      });
    }else{
      arr[findArr].invoices.push({ invoice_num: a.invoice_num, credit: a.credit });
    }
  }

  const rows: GridRowsProp = arr.length > 0 ? arr.sort((a,b) => a.rawDate < b.rawDate ? 1 : -1) : [];
  // const rows: GridRowsProp = arr.length > 0 ? arr.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1) : [];

  const columns: GridColDef[] = [
    // { headerName: "ID", field: "uuid", width: 100, pinnable: false }, 
    { headerName: "Reference", field: "reference", width: 250, pinnable: false }, 
    { headerName: "Payment Amount", field: "paymentAmount", width: 150, pinnable: false }, 
    { headerName: "Invoices (Amount Deducted)", field: "invoice_num", width: 350, pinnable: false, 
      renderCell: (params) => {
      const row = params.row;
      return <>
          <ul>
            {row.invoices.map(i => {
              return(
              <li>
                #{i.invoice_num} ({i.credit})
              </li>
              );
            })}
          </ul>
        </>
      }
    }, 
    // { headerName: "Amounts Deducted", field: "credit", width: 150, pinnable: false }, 
    { headerName: "Date", field: "transactionDate", width: 150, pinnable: false }, 
    { headerName: "Date Payment Made", field: "createdAt", width: 150, pinnable: false }, 
    { headerName: "Payment Type", field: "paymentType", width: 200, pinnable: false },
    /*
    {
      field: 'actions',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        const row = params.row;
        return (
          <>
          <Button
            colour="action"
            label="View"
            onClick={() => history.push(`/matters/${row.matter}/debtors/${row.debtor}/debt/${row.debt}/?payments=true`)}
            icon={GiMagnifyingGlass}
          />
          {/*  
          <ConfirmModal
            open={modalOpen !== ""}
            handleClose={() => setDeleteConfirm(false)}
            handleSubmit={() => {
              API.matters.debtors.debt.removeTransaction(row.matter, row.debtor, row.debt, row.id).then(res => {
                if(res.success){
                  toast.success(res.message)
                }else{
                  toast.error(res.message)
                }
              })
            }}
            header="Are you sure?"
            content={
              "Deleting is an irreversible action and values of outstanding values will be regenerated!"
            }
          />
          <Button
            className="ml-2"
            colour="alert"
            onClick={() => API.matters.debtors.debt.removeTransaction(row.matter, row.debtor, row.debt, row.id).then(res => {
              if(res.success){
                toast.success(res.message);
                history.reload();
              }else{
                toast.error(res.message)
              }
            })}
            icon={TrashIcon}
          />*-/}
          </>
          
        );
      },
    }*/
    // { headerName: "Due Date", field: "due", width: 150, pinnable: false },
  ];

  const columnsWithFooter = columns.map((column) => {
    if (column.field === 'credit') {
      return {
        ...column,
        footerName: 'Total',
        footerRenderer: () => total_amount_payments,
      };
    }
    return column;
  });
  

  return (
    <>
      <table className=" w-full  border border-gray-400">
        <thead>
          <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
            <th className="font-medium  ">Date</th>
            <th className="font-medium  ">Days</th>
            <th className="font-medium ">Invoice</th>
            <th className="font-medium ">Debtors</th>
            <th className="font-medium  ">Amount</th>
            <th className="font-medium  ">Amount Paid</th>
            <th className="font-medium  ">Interest</th>
            <th className="font-medium ">Compensation</th>
            <th className="font-medium ">Legal Costs</th>
            <th className="w-1/6 font-medium ">Total</th>
            <th className="w-1/6 font-medium ">Outstanding Balance</th>
          </tr>
        </thead>
        <tbody className="p-2 divide-y divide-gray-300 ">
          {debts.sort((a, b) => dayjs(a.invoiceDate).isBefore(dayjs(b.invoiceDate))).map((d, i) => {
            let totalInt = 0;
            let lineTotal = 0;
            let days = 0;
            if (d.amount) {
              days = dayjs().diff(dayjs(d.interestDate), "day");
              totalInt = getInterest(d.amount, d.interest) * days;
              lineTotal = 
              d.amount 
              + (totalInt ? totalInt : 0)
              + (d.compensation ? d.compensation : 0)
              + (d.recoverable_legal_costs ? d.recoverable_legal_costs : 0);
            } else {
              lineTotal = d.credit;
            }

            let due = lineTotal;

            lineTotal = due - (d.AmountPaid ? d.AmountPaid : 0);

            return (
              <tr
                className="p-2 divide-x divide-gray-300 cursor-pointer hover:bg-gray-50"
                key={i}
                onClick={() =>
                  history.push(
                    `/matters/${d.matter}/debtors/${d.debtor}/debt/${d._id}`
                  )
                }
              >
                <td className="p-2">
                  {d.invoiceDate && dayjs(d.invoiceDate).format("DD-MM-YYYY") + " "}
                  {d.interestDate && "Interest from: " + dayjs(d.interestDate).format("DD-MM-YYYY")}
                </td>
                <td className=" text-center p-2 ">{days}</td>
                <td className="  p-2">{d.invoice_num}</td>
                <td className="  p-2">{Array.isArray(d.detors) ? d.detors.join(", ") : d.detors}</td>
                <td className=" text-right p-2">
                  £{d.amount ? CommonFunctions.toFormattedFixed(d.amount) : "0.00"}
                </td>
                <td className=" text-right p-2">
                  £{d.AmountPaid ? CommonFunctions.toFormattedFixed(d.AmountPaid) : "0.00"}
                </td>
                {/* <td className=" text-right p-2 border-r border-gray-300">
                  £{d.credit ? d.credit.toFixed(2) : "0.00"}
                </td> */}

                <td className=" text-right p-2 ">
                  £{CommonFunctions.toFormattedFixed(totalInt)}{" "}
                  <span className="text-sm italic">
                    ({d.rateType} @ {d.interest} %)
                  </span>
                </td>
                <td className=" text-right p-2 ">£{d.compensation ? CommonFunctions.toFormattedFixed(d.compensation) : "0.00"}</td>
                <td className=" text-right p-2 ">£{d.recoverable_legal_costs ? CommonFunctions.toFormattedFixed(d.recoverable_legal_costs) : "0.00"}</td>
                <td className=" text-right p-2 ">£{due && CommonFunctions.toFormattedFixed(due)}</td>
                <td className=" text-right p-2 ">£{lineTotal && CommonFunctions.toFormattedFixed(lineTotal)}</td>
              </tr>
            );
          })}
          <tr className="p-2 divide-x divide-gray-300 bg-gray-200">
            <td> </td>
            <td> </td>
            <td> </td>
            <td> </td>
            <td className="text-right p-2 border-r border-gray-400">
              Amount: £{CommonFunctions.toFormattedFixed(total_amount)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Paid: £{CommonFunctions.toFormattedFixed(total_paid)}
            </td>
            {/* <td className="text-right p-2 border-r border-gray-400">
              Credit: £{credit.toFixed(2)}
            </td> */}
            <td className="text-right p-2 border-r border-gray-400">
              Interest: £{CommonFunctions.toFormattedFixed(interest)}{" "}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Compensation: £{CommonFunctions.toFormattedFixed(compensation)}
            </td>
            <td className="text-right p-2 border-r border-gray-400">
              Legal Costs: £{CommonFunctions.toFormattedFixed(recoverable_legal_costs)}
            </td>
            <td className="w-1/6 p-2 text-right">Total Due: £{CommonFunctions.toFormattedFixed(total_due)}</td>
            <td className="w-1/6 p-2 text-right">Total Balance: £{CommonFunctions.toFormattedFixed(total)}</td>
          </tr>
        </tbody>
      </table>
      <h2 className="mt-5">Payments:</h2>

      <DataGridPro rows={rows} columns={columns} initialState={{ pinnedColumns: { left: ['uuid'], right: ['due', 'actions'] } }} />
    </>
    
  );
}
