import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button, Toggle } from "../../../components/Forms";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Segment from "../../../components/Segment";
import { useParams } from "react-router-dom";

export default function DocManagement({ history }) {
  const [listData, setListData] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showSub, setShowSub] = useState(true);
  const [showActive, setShowActive] = useState(true);

  const params = useParams();

  useEffect(() => {
    if (mount) {
      setMount(false);
      setLoading(true);

      API.settings
        .doc_types()
        .list(params.org_id)
        .then((res) => setListData(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const renderTable = (header, body) => (
    <div className={`text-lg`}>
      <Button
        icon={PlusIcon}
        colour="positive"
        label="Add"
        style={{ float: "right" }}
        onClick={() => {
          history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_types/add` : "/settings/doc_types/add");
        }}
      />
      <div
        style={{ float: "right" }}
      >
        <Toggle
          className="mt-2 mr-2"
          enabled={showSub}
          onChange={() => setShowSub(!showSub)}
          label={"Show Sub Areas"} />
      </div>
      <div
        style={{ float: "right" }}
      >
        <Toggle
          className="mt-2 mr-2"
          enabled={showActive}
          onChange={() => setShowActive(!showActive)}
          label={showActive ? "Show Active Only" : "Show All"} />
      </div>
      <div
        style={{ float: "right" }}
      >
        <Toggle
          className="mt-2 mr-2"
          enabled={showArchived}
          onChange={() => setShowArchived(!showArchived)}
          label={"Show Archive"} />
      </div>
      
      <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
        <span className="my-auto text-xl">Areas of Law</span>
      </h3>

      <br />

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {header.map((header_item, i) => {
                      return (
                        <th
                          key={"th_" + i}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header_item.text}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {body.length > 0 ? (
                    body.filter(e => !showArchived ? e.archived !== true : true).map((body_item, body_index) => (
                      <tr key={body_index}>
                        {header.map((header_item, header_index) => (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {body_item[header_item.value]} 
                            {header_item.value === "name" && body_item.archived ? 
                            <span className="ml-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                              Archived
                            </span> : ""}
                            {header_item.value === "name" && body_item._clioId && body_item._clioId !== "" ? 
                            <span className="ml-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/10">
                              Synced from Clio
                            </span> : ""}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan={header.length}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                    >
                      No Data...
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const capitalizeFirstLetter = (string) => {
    if(string)
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      {/*  
      <ConfirmModal
        open={modalOpen !== ""}
        handleClose={() => setModalOpen("")}
        handleSubmit={() => {
          API.settings
            .email_templates()
            .remove(modalOpen)
            .then(() => {
              setMount(true);
              setModalOpen("");
            })
            .catch((e) => CommonFunctions.handleError(e));
        }}
        header="Are you sure?"
        content={
          "Deleting is an irreversible action, but you can re-add an item with the same name."
        }
      />*/}
      <Segment>
        {loading ? (
          <Loader />
        ) : (
          renderTable(
            [
              { text: "Type", value: "name" },
              { text: "Shortcode", value: "shortcode" },
              { text: "Parties", value: "parties" },
              { text: "Parent", value: "parent" },
              { text: "Active", value: "active" },
              { text: "Actions", value: "action_btn" },
            ],
            listData.filter(d => (showSub ? true : !d.parent) && (showActive ? d.active == true || (showArchived ? d.archived == true : false) : true) ).map((item) => ({
              ...item,
              active: item.active ? "✓" : "X",
              parties: Array.isArray(item.parties) ? item.parties.map(p => {
                return p === "contact" ? "Client (3rd Party)" : capitalizeFirstLetter(p);
              }).join(",") : capitalizeFirstLetter(item.parties),
              parent: item.parent ? item.parent.name : "--",
              action_btn: (
                <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                  <Button
                    icon={PencilIcon}
                    colour="action"
                    className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                    onClick={() => {
                      history.push(
                        params.org_id ? `/admin/organisations/${params.org_id}/doc_types/edit/${item._id}` :
                        "/settings/doc_types/edit/" + item._id
                      );
                    }}
                  />
                  {/*  
                  <Button
                    icon={TrashIcon}
                    colour="red"
                    className="md:mr-auto md:mr-2"
                    onClick={() => {
                      setModalOpen(item._id);
                    }}
                  />*/}
                </div>
              ),
            }))
          )
        )}
      </Segment>
    </>
  );
}
