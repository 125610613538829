import React, { useState, useEffect } from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../api";
import MatterBar from "../_components/MatterBar";
import toast from "react-hot-toast";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import CommonFunctions from "../../../CommonFunctions";
import Add_Payment_Form from "./Fee_Payment_Form";
import Loader from "../../../components/Loader";
function AddPayment({ matter, history }) {

  const [loading, setLoading] = useState(true);
  const [mountLoading, setMountLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});
  const [fees, setFees] = useState([]);
  const [payments, setPayments] = useState([]);

  const [matterDebtors, setMatterDebtors] = useState({});

  useEffect(() => {
    API.matters.fees.getAll(matter._id).then((fees) => {
      setFees(fees)

      let tran = [];

      fees.map(f => {
        f.transactions.map(t => {
          tran.push(t)
        })
      })

      setPayments(tran);

    })
      .finally(() => setMountLoading(false)) 
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  useEffect(() => {
    if(loading)
      Promise.all([
        API.settings.base_rates.normal.get(),
        // API.matters.fees.
    ])
      .then((data) => {
        if(data[0][0]){
          setBaseRate(data[0]);
        }
        setLoading(false);
      });
  }, [baseRate]);
  const handleData = (data) => {

    API.matters.fees.addTransaction(matter._id, {...data}).then((res) => {
      if(res.success){
        toast.success(`Fee Payment Added Successfully`);
        history.push(`/matters/${matter._id}/fees`);
      }else{
        toast.error(`Fee Payment Error - ${res.message}`);
      }
    }).catch((e) => CommonFunctions.handleError(e));


    return;

    API.matters.debtors.debt.addTransaction(matter._id, null,null, {...data, multiple: true}).then((res) => {
      if(res.success){
        toast.success(`Payment Added Successfully`);
        history.push(`/matters/${matter._id}/debts`);
      }else{
        toast.error(`Payment Error - ${res.message}`);
      }
    }).catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <div className="w-full  ">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Fees", href: `/matters/${matter._id}/fees`,},
        { name: "Add Payment", href: `#`, current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        {mountLoading ? <Loader /> :
        <Add_Payment_Form
          data={handleData}
          debts={fees}
          payments={payments}
          matter_display_number={matter.display_number}
          invoiceType={"FI"}
        />}
      </Segment>
    </div>
  );
}
export default ChainedContext(AddPayment, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
